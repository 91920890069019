<template>
	<div class="history-page">
		<navBar>
			<span class="title">{{title}}的学习报告</span>
		</navBar>
		<van-tabs
			@click="changeCourse"
			v-if="allData.length > 0"
			style="border-top: 1px solid #dcdcdc"
			color="#2db79a"
			title-active-color="#2db79a"
			:line-width="20"
		>
			<van-tab :title="item.title" :key="index+'tabl'" v-for="(item, index) in allData" />
		</van-tabs>
		<div class="ceBox" v-if="!show">
			<div class="item" @click="show = true">
				{{courseData.children[ceNav]? courseData.children[ceNav].name:'' }}
				<van-icon name="arrow" />
			</div>
		</div>
		<div class="childBox" v-if="show">
			<div
				class="item"
				:key="index + item.id"
				v-for="(item, index) in courseData.children"
				@click="
					ceNav = index;
					show = false;
				"
			>
				{{ item.name }}
			</div>
		</div>
		<div class="page-content" style="">
			<courseList
				class="courseList"
				:courseData="courseData.children[ceNav].data"
				v-if="courseData.children[ceNav]&&courseData.children[ceNav].data.length>0"
			></courseList>
			<div v-else style="font-size: 0.24rem; text-align: center;padding-top: 25%;">暂无该科学习记录</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import courseList from '@/components/courseList.vue';
export default {
	name: 'moreHistory',
	components: {
		courseList
	},
	data() {
		return {
			courseData: [],
			allData: [],
			activeNav: 0,
			ceNav: 0,
			show: false,
			title: ''
		};
	},
	computed: {
		...mapGetters({
			userInfs: 'userCenter/getUserInfs'
		})
	},
	created() {
		this.getLastLearn();
		this.title= this.userInfs.username;
	},
	mounted() {},
	methods: {
		getLastLearn() {
			let that = this;
			that.$api
				.getUserLearnings({
					type: 1
				})
				.then((res) => {
					let resData = res.data || [];
			
					for (let i = 0; i < resData.length; i++) {
						let el = resData[i];
						if (el.children) {
							let childrenData = [];
							for (let var1 in el.children) {
								let keyData = [];

								if (el.children[var1]) {
									for (let key in el.children[var1]) {
										keyData.push(el.children[var1][key]);
									}
								}
								childrenData.push({ name: var1, data: keyData });
							}
							el.children = childrenData;
						}
					}
					this.allData = resData;
					this.courseData = resData[this.activeNav];
					console.log(this.courseData);
					console.log(resData);
				});
		},
		changeCourse(idx) {
			if (idx != 100) {
				this.courseData = this.allData[idx];
			}
			this.activeNav = idx;
			this.ceNav = 0;
		}
	}
};
</script>

<style lang="scss" scoped>
.history-page {
	padding-top: 1.08rem;
}
.page-content {
	padding: 0.28rem;
	height: calc(100vh - 2.8rem);
	min-height: calc(100vh - 2.8rem);
}
.course-nav {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0.55rem;
	li {
		position: relative;
		margin-right: 0.4rem;
		font-size: 0.28rem;
		font-weight: 400;
		color: #909090;
		transition: all 0.3s ease-in;
		&::after {
			position: absolute;
			left: 50%;
			bottom: -0.15rem;
			height: 0.04rem;
			width: 0.24rem;
			content: '';
			background: #28b396;
			border-radius: 0 02rem;
			opacity: 0;
			transition: all 0.3s ease-in;
			transform: translateX(-50%);
		}
		&::before {
			position: absolute;
			left: 0;
			right: 0;
			z-index: 2;
			bottom: -0.05rem;
			height: 0.1rem;
			content: '';
			background: #fff;
		}
		&.active {
			position: relative;
			font-weight: 500;
			font-size: 0.28rem;
			color: #28b396;
			&::after {
				opacity: 1;
			}
		}
	}
}
.ceBox,
.childBox {
	border-top: 5px solid #dcdcdc;
	border-bottom: 5px solid #dcdcdc;
	font-size: 0.24rem;
	.item {
		border-bottom: 1px solid #dcdcdc;
		padding: 10px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
</style>
